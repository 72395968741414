.logo{
  width: 90px;
}
.img-product{
  width: 100px; 
  height: 100px;
  background: gainsboro;
}
.add-img{
  background: gainsboro;
  font-size: 30px;
}
.dashboard-percent{
  width: 100%;
}
.test-percent{
  width: 80%;
}
.test-persent-create{
  width: 10%;
}
.discount-persent{
  width: 120px;
}
.table-full{
  width:100%;
}
.state-rotate{
  height: 20px;
  width: 20px;
}
.image-up{
  width: 70px; 
  height: 70px;
  cursor: pointer;
}
.bar-code{
  position: absolute;
}
.img-full{
  width: 100%;
  max-width: 40rem;
  max-height: 40rem;
}
.img-only-height{
  max-height: 500px;
}


#root {
  display: grid;
  grid-template-rows: 3rem auto;
  grid-template-areas: 
    "pim-navbar"
    "pim-content";
  overflow-y: hidden;
}

.dimention-nav-bar{
  grid-row: pim-navbar;
}
.dimention-content{
  grid-row: pim-content;
  display: grid;

  grid-template-rows: 3rem 13.5rem calc(100vh - 18rem);
  grid-template-columns: 1fr 2fr;
  grid-template-areas:
    "product-new   product-detail"
    "search        product-detail"
    "product-list  product-detail";
}
#product-new {
  grid-area: product-new;
}
#search {
  grid-area: search;
}
#paginator {
  grid-area: paginator;
}
#product-list {
  grid-area: product-list;
  max-width: 100%;
  overflow-y: auto;
}
#product-detail {
  grid-area: product-detail;
  max-width: 100%;
  overflow-y: auto;
}
#product-detail::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: white;
}

#product-detail::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}
#product-detail::-webkit-scrollbar-thumb {
  background-color: #544f92;
  outline: 1px solid white;
}
#product-list::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  background-color: white;
}

#product-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px white;
}
#product-list::-webkit-scrollbar-thumb {
  background-color: #544f92;
  outline: 1px solid white;
}
.was-validated .form-control:invalid {
  padding-right: inherit !important;
  background-image: none !important;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: inherit !important;
  background-image: none !important;
}
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #495057 !important;
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(101, 111, 121, 0.5);  
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: inherit;
}
.bg-progress{
  background-color:rgb(40, 167, 69) !important;
}
.progress {
  line-height: 1.5;
  border-radius: 0rem;
}
.form-control.is-invalid:focus {
  border-color: #dc3545 !important;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
}

.form-control.is-invalid {
  border-color: #dc3545 !important;
  padding-right: inherit !important;
    background-image: none !important;
}
.position-fixed {
  position: fixed !important;
  z-index: 10;
  background: white;
}
.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #ced4da;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.38);
  border-color: #544f92;
}

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.38);
  border-color: #544f92;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #544f92;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #544f92;
  background-color: #544f92;
}

.non-data-descript{
  height: 42px;
}

.bg-grey {
  background-color: #e9ecef !important;
}

.text-progressbar{
  color: rgb(40, 167, 69); 
  mix-blend-mode: difference;
}

.table-text-new{
  font-size: 16px;
  font-style: normal;
}

.table-header-border{
  border-bottom: 1px solid #BFBFBF;
}

.table-text{
  font-style: normal;
  font-weight: 700;
}

.attributes-form{
  align-items: center;
}

h3, h4, h5, h6, p.lead {
  font-weight: 600;
  color: #595091;
}

.bolder-text {
  font-weight: 600;
}

input[type="checkbox"]{
  appearance: none;
  width: 1.125rem;
  height: 1.125rem;
  border: 1px solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
}

input[type="checkbox"]:checked{
  border-color: #544f92;
  background-color:#544f92;
}

input[type="checkbox"]:not(:checked){
  border-color: #F0F0F0;
  background-color:#F0F0F0;
  border: 1px solid #CECECE;
}

.options-table .table-sku-border{
  border-right: 1px solid #BFBFBF;
}

.pim-border{
  border-top: 2px solid rgba(0,0,0,.1);
}

#product-detail {
  background-image: url(../img/hbt.png);
  background-position: center center;
  background-repeat: no-repeat;
}
.Product-show {
  background-color: white;
}
.react-tagsinput-input {
  width: 100% !important;
}

.list-group-item.active {
  background-color: #544f92 !important;
  border-color: #544f92 !important;
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.react-tagsinput--focused {
  border-color: #544f92 !important;
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.38) !important;
  border-color: #544f92 !important;
}

.react-tagsinput-tag {
  background-color: #544f92 !important;
  border-radius: 2px !important;
  border: 1px solid #544f92 !important;
  color: #ffffff !important;
  display: inline-block !important;
  font-family: sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  margin-bottom: 5px !important;
  margin-right: 5px !important;
  padding: 5px !important;
}

.react-tagsinput-input {
  background: transparent  !important;
  border: 0  !important;
  color: #495057  !important;
  font-family: sans-serif  !important;
  font-size: 1rem  !important;
  font-weight: 400  !important;
  margin-bottom: 6px  !important;
  margin-top: 1px  !important;
  outline: none  !important;
  padding: 5px  !important;
}

@media (min-width: 2px) {
  .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto;
  }
}

.btn-outline-primary {
  color: #544f92;
  border-color: #544f92;
}

.btn-outline-primary:hover {
  color: #544f92;
  background-color: transparent;
  border-color: #544f92;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
  color: #544f92;
  background-color: transparent;
  border-color: #544f92;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.45);
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 79, 146, 0.45);
}

.description-input-text {
  display: flex;
  flex-direction: column;
}
