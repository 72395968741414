$primary-line-color: #544f92;
$primary-color: #544f92;
$muted-color: #6a678f;
$bg-color: white;
$dot-color: #333;

$dot-size: 6px;
$dot-space: 12px;

$delete-color: #790f0f;



.color-primary {
  color: $primary-color;
}


h1, h2 {
  // font-family: 'Roboto';
  // text-transform: uppercase;
  color: #595091;
}

h3, h4, h5, h6, p.lead {
  font-family: 'Museo', sans-serif;
  font-weight: 500;
  color: #595091;
}


.btn-primary {
  border-color: $primary-color;
}

.btn.btn-primary {    
  background-color: $primary-color !important;
  border-color: $primary-color !important;
}
.text-primary{
  color: #595091 !important;
}

.border-primary{
  border-color: $primary-color !important;
}