.trix-button.trix-button--icon.trix-button--icon-heading-1{
  display: none;
}

.trix-button.trix-button--icon.trix-button--icon-quote {
  display: none;
}
.trix-button.trix-button--icon.trix-button--icon-code.trix-active{
  display: none;
}
.trix-button-group.trix-button-group--file-tools{
  display: none;
}
