.product-prices-detail-layout {
  display: grid;
  height: 100%;
  margin: 2rem 8rem 4rem 8rem;
  grid-template-rows: clamp(3rem, 4rem, 6rem) clamp(3rem, 4rem, 6rem) clamp(5rem, 8rem, 10rem) auto;
  grid-template-columns: calc(100%/3) calc(100%/3) calc(100%/3);
  grid-template-areas:
    "return-button . ."
    "title . ."
    "approved-changes pending-changes rejected-changes"
    "detail-table detail-table detail-table";
}

.product-prices-detail-layout .return-button {
  grid-area: return-button;
  align-self: center;
}

.product-prices-detail-layout .title {
  grid-area: title;
  align-self: center;
}

.product-prices-detail-layout .approved-changes {
  grid-area: approved-changes;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.product-prices-detail-layout .pending-changes {
  grid-area: pending-changes;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.product-prices-detail-layout .rejected-changes {
  grid-area: rejected-changes;
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
}

.product-prices-detail-layout .detail-table {
  grid-area: detail-table;
}

.black-text {
  color: black;
}

.warning-text {
  color: #DC3545;
}
