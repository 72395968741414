.product-prices-layout {
  display: grid;
  height: 100%;
  margin: 2rem 8rem 4rem 8rem;
  grid-template-rows: clamp(3rem, 4rem, 6rem) auto;
  grid-template-columns: 70% 30%;
  grid-template-areas:
    "title upload-button"
    "upload-table upload-table";
}

.product-prices-layout .title {
  grid-area: title;
  align-self: center;
}

.product-prices-layout .upload-button {
  grid-area: upload-button;
  place-self: center;
  margin-bottom: 1rem;
}

.product-prices-layout .upload-table {
  grid-area: upload-table;
}

.product-prices-layout .upload-table .file-column {
  width: 70%;
  vertical-align: middle;
}

.product-prices-layout .upload-table .detail-column {
  width: 30%;
  text-align: center;
}
