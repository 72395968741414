.page-link:hover {
  z-index: 0;
  color: #595091;
  text-decoration: none;
  background-color: inherit;
  border-color: gainsboro;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: none;
}